import React from 'react';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Slogan from '../components/slogan';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';
import CollapsablePanel from '../components/collapsable-panel';

const Faq = () => (
  <Layout>
    <SEO
      title="FAQ"
      description="Heeft u toch nog vragen? Bekijk gerust de veelgestelde vragen. En ontdek waarom onze pizzabus op uw feest thuishoort."
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{
        backgroundImage: 'url(/design/img/header/afgewerkte-pizza.jpg)',
        backgroundPosition: 'bottom',
      }}
    >
      <div className="container">
        <Slogan heading="De veelgestelde vragen" />
      </div>
    </header>

    <section
      className="pattern-bg py-8"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <h2 className="title text-center">FAQ</h2>

        <CollapsablePanel title="Hoe verloopt zo’n pizzafeest?">
          <p>
            Wij komen ongeveer 1 uur vóór aanvang op de aangegeven locatie aan.
            Dan gaan wij samen met jou na waar we de oven en de buffettafel best
            plaatsen. Wij opteren hierbij duidelijk voor een plaatsing zo dicht
            mogelijke bij de genodigden. Uiteraard kan de pizzaoven enkel buiten
            opgesteld staan; de buffettafel kan, maar moet niet, binnenshuis.
            Slecht weer deert niet omdat de oven steeds onder een mooie tent
            wordt geplaatst, wat het effect van een <strong>pizzabus</strong>{' '}
            creëert. De pizza’s worden door ons personeel tot op de buffettafel
            gebracht. Vanaf het begin van het feest worden verschillende soorten
            pizzastukken tegelijkertijd aangeboden. Dit kan omdat het bakken van
            de pizza, eigenlijk twee per oven, dank zij de hoge temperatuur van
            zo’n 400°C, slechts 1 minuut duurt. Iedere genodigde eet zoveel hij
            wil, van welke pizza soort dan ook!
          </p>
          <p>
            Onze ervaring leert ons dat het gemiddeld ongeveer 1,5u duurt
            vooraleer iedereen zijn buikje heeft volgepropt.
          </p>
          <p>
            Nadat het eten van de pizza’s gestopt is, starten wij onmiddellijk
            met het opruimen. Na een halfuurtje zijn wij daar volledig mee klaar
            en vertrekken wij.
          </p>
        </CollapsablePanel>

        <CollapsablePanel
          title="
Vanaf hoeveel genodigden komen jullie tot bij ons?"
        >
          Wij plaatsen onze mobiele pizzahoutoven op allerlei feesten:
          verjaardagsfeesten, communiefeesten, babyborrel, op-pensioen-stelling,
          huwelijksfeesten, gewone vriendschapsfeesten, enz...
          <br />
          Wij organiseren pizzafeestjes vanaf 20 personen. Wij verzorgden ook
          reeds feesten met zo’n 1000 gasten. Een en ander wordt mogelijk
          gemaakt door het inzetten van meerdere houtovens. Ons personeel wordt
          opgeleid en heeft voldoende ervaring opgedaan om in alle
          omstandigheden professioneel, vriendelijk en gedreven op te treden.
        </CollapsablePanel>
        <CollapsablePanel
          title="
Is een pizzafeest niet iets voor jongeren?
"
        >
          <p>
            Wij organiseerden tot hiertoe evenementen voor zoveel redenen als je
            je kunt inbeelden: geboorte, babyshower, verjaardag, pensioen,
            communie, huwelijk, vriendschap, enz. Ook voor bedrijven organiseren
            wij pizzafeesten en dat om diverse redenen: Nieuwjaar, promotie,
            afscheid, goede bedrijfsresultaten, uitbreiding, enz.
          </p>
          <p>
            Eigenlijk staat onze agenda het ganse jaar door volgeboekt. Tijdig
            reserveren is een aanrader!
          </p>
        </CollapsablePanel>

        <CollapsablePanel
          title="
Onze locatie is niet vlot bereikbaar. Geraken jullie daar?"
        >
          Onze pizzaovens hebben een breedte van slechts 70 cm, waardoor ze
          makkelijk door een standaarddeur kunnen gereden worden. Ze zijn zo’n
          90 cm lang en 170 cm hoog. Het transportsysteem maakt gebruik van
          luchtbandjes, waardoor een grindpad of een zompige weide of besneeuwde
          weg geen enkel probleem vormt. De pizzaoven wordt onder een tent
          geplaatst met afmetingen 3m x 3m.
        </CollapsablePanel>
        <CollapsablePanel title="Is er brandgevaar?">
          Neen, totaal niet, op geen enkel ogenblik. De houtblokken worden diep
          in de oven tot ontbranding gebracht. Geen vlammetje kan ooit uit de
          pizzaoven te voorschijn komen. Onze ovens vormen voor niemand, ook
          niet voor de allerkleinsten, ooit enig gevaar.
        </CollapsablePanel>

        <CollapsablePanel
          title="
Ik heb een voedselallergie. Gaat het pizzafeest dan aan mij
voorbij?"
        >
          Natuurlijk niet. Je hoeft enkel tijdig aan te geven waarmee wij
          rekening moeten houden. In het verleden hebben wij geregeld gluten- of
          lactosevrije pizza’s op feesten gebakken. Meld ons welke
          voedingstoffen wij moeten vermijden en wij voorzien zoveel pizza’s als
          je wenst die beantwoorden aan je voedselallergie.
        </CollapsablePanel>

        <CollapsablePanel
          title="
Wat hebben jullie praktisch nodig op onze locatie?"
        >
          <p>
            Niets … behalve misschien een emmertje met water. Of toch, wat
            absoluut nodig is, is gasten die goedgehumeurd zijn, zodat, in
            combinatie met het enthousiaste optreden van ons team, alle
            ingrediënten aanwezig zijn om jouw feest het succes te doen kennen
            dat het verdient.
          </p>
        </CollapsablePanel>
      </div>
    </section>
    <Contact dark="true" />

    <Footer />
  </Layout>
);

export default Faq;
